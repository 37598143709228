import moment from "moment";
import "moment/locale/nl";
import modulo from "./modulo";
import simpleSFX from 'simple-sfx';

moment.locale('nl');

let _ = require('underscore');
let truncate = require('truncate');

let AnimationFrame = require('./lib/animation-frame');

const sfx = new simpleSFX([
    window.gs_data.SITE_URL + 'sounds/bump.ogg',
    window.gs_data.SITE_URL + 'sounds/bump.mp3',
    window.gs_data.SITE_URL + 'sounds/chew.ogg',
    window.gs_data.SITE_URL + 'sounds/chew.mp3'
]);

const drill_sfxs = [
  new simpleSFX([
    window.gs_data.SITE_URL + 'sounds/drill.ogg',
    window.gs_data.SITE_URL + 'sounds/drill.mp3'
  ]),
  new simpleSFX([
    window.gs_data.SITE_URL + 'sounds/drill.ogg',
    window.gs_data.SITE_URL + 'sounds/drill.mp3'
  ]),
  new simpleSFX([
    window.gs_data.SITE_URL + 'sounds/drill.ogg',
    window.gs_data.SITE_URL + 'sounds/drill.mp3'
  ]),
];

let LIST_ITEMS = 3,
  games_list = window.gs_data.games_list,
  games_count = games_list.length,
  cur_row = 0,
  animation_time = 500,
  stagger_time = 160,
  is_animating = false;

let w = window,
  d = document,
  e = d.documentElement,
  g = d.getElementsByTagName('body')[0],
  sw = w.innerWidth || e.clientWidth || g.clientWidth,
  sh = w.innerHeight || e.clientHeight || g.clientHeight;


/**
 * 
 * @param {*} el 
 * @param {*} face 
 * @param {*} sub 
 * @param {*} value 
 */
const setListItemContent = (el, face, sub, value) => {
  el.querySelector(
    `.item-face-${face} .item-face-${sub}`
  ).textContent = value;
};

const setListItemHtml = (el, face, sub, value) => {
  el.querySelector(
    `.item-face-${face} .item-face-${sub}`
  ).innerHTML = value;
};

const setListItemHref = (el, face, sub, value) => {
  el.querySelector(
    `.item-face-${face} .item-face-${sub}`
  ).href = value;
};

/**
 * Show next or previous three games
 * 
 * @param {number} dir 
 *  Direction: 1 is right, -1 is left
 * 
 * @param {boolean} skipTransition 
 *  Possibility to skip 3D transition
 */
const nextGames = (dir, skipTransition) => {
  cur_row += (dir || 1);

  // determine face
  let face = skipTransition ? "front" : (dir && dir === -1 ? "left" : "right");

  // populate
  for (
    let n = 1, i = (cur_row - 1) * LIST_ITEMS;
    i < cur_row * LIST_ITEMS;
    n++ , i++
  ) {
    // let i_mod = modulo(i, games_count);
    let game = games_list[modulo(i, games_count)];
    let itemEl = document.getElementById(`g-list-item-${n}`);
    let metaContent = 'Gemaakt: ' + moment(game.created, "YYYY-MM-DD HH:mm:ss").format('D MMM YYYY') + (game.updated ? '<br>Laatste update: ' + moment(game.updated, "YYYY-MM-DD HH:mm:ss").format('D MMM YYYY') : '');
    if (game.featured) {
      metaContent += '<div class="meta-icon featured"></div>';
    } else if (game.new) {
      metaContent += '<div class="meta-icon new"></div>';
    } else if (window.gs_data.has_popular && game.popular) {
      metaContent += '<div class="meta-icon popular"></div>';
    }
    // let authorName = S(game.author.name).latinise().truncate(window.gs_data.trunc_alias_amount, '..').s;
    let authorName = truncate(game.author.name, window.gs_data.trunc_alias_amount, {ellipsis:".."});
    // let gameTitle = S(game.title).latinise().truncate(window.gs_data.trunc_title_amount).capitalize().s;
    let gameTitle = truncate(game.title, window.gs_data.trunc_title_amount, {ellipsis:".."});
    // let gameDesc = S(game.description).latinise().truncate(window.gs_data.trunc_desc_amount).capitalize().s;
    let gameDesc = truncate(game.description, window.gs_data.trunc_desc_amount, {ellipsis:"..."});
    setListItemContent(itemEl, face, 'content-title', gameTitle);
    setListItemContent(itemEl, face, 'content-desc', gameDesc);
    setListItemContent(itemEl, face, 'authorlink', authorName);
    setListItemHref(itemEl, face, 'authorlink', `maker/${game.author._id}`);
    setListItemHtml(itemEl, face, 'meta', metaContent);
    itemEl.querySelector(`.item-face-${face} .item-face-picture`).style.backgroundImage = `url(${game.picture})`;
    itemEl.querySelector(`.item-face-${face} .item-face-link`).href = `${window.gs_data.SITE_URL}speel/${game._id}`;
    itemEl.parentNode.classList.add("noshadow");
    
    // animation
    if (skipTransition) { 
      itemEl.parentNode.classList.remove("noshadow");
      continue; 
    }
    setTimeout(() => {
      itemEl.classList.remove("state-front");
      itemEl.classList.add(dir && dir === -1 ? "state-left" : "state-right");
      drill_sfxs[n-1].play({
        name: 'drill',
        volume: 0.4,
        loop: false
      });
      setTimeout(metaContent => {
        setListItemContent(itemEl, 'front', 'content-title', gameTitle);
        setListItemContent(itemEl, 'front', 'content-desc', gameDesc);
        setListItemContent(itemEl, 'front', 'authorlink', authorName);
        setListItemHref(itemEl, 'front', 'authorlink', `maker/${game.author._id}`);
        setListItemHtml(itemEl, 'front', 'meta', metaContent);
        itemEl.querySelector(`.item-face-front .item-face-picture`).style.backgroundImage = `url(${game.picture})`;
        itemEl.querySelector(`.item-face-front .item-face-link`).href = `${window.gs_data.SITE_URL}speel/${game._id}`;
        itemEl.classList.remove("state-right", "state-left");
        itemEl.classList.add("state-front");
        itemEl.parentNode.classList.remove("noshadow");
      }, animation_time + 10, metaContent);
    }, n * stagger_time);
  }

  return true;
};


/**
 * Handles arrow clicks
 * 
 * @param {Event} e 
 */
const arrowClickHandler = e => {
  e.preventDefault();
  if (is_animating) { return false; }
  is_animating = true;
  nextGames(e.target.id === 'g-list-ctrl-right' ? 1 : -1);
  setTimeout(() => is_animating = false, animation_time + LIST_ITEMS * stagger_time + 10);
};
document.getElementById('g-list-ctrl-left').addEventListener('click', arrowClickHandler);
document.getElementById('g-list-ctrl-right').addEventListener('click', arrowClickHandler);


// kickoff
nextGames(1, true);


/**
 * =======================================================================
 * 
 *  Slime
 * 
 * =======================================================================
 */

const slimeStage = d.querySelector('.slime-stage');
if (slimeStage) {
    let slime = d.createElement("div");
    slime.className = "slime";
    slime.style.left = ((0.5 * sw) - (0.35 * sw) + (Math.random() * 0.7 * sw)) + "px";
    slimeStage.appendChild(slime);

    slime.addEventListener('click', e => {
      slime.classList.add("eat");
      setTimeout(e => {
        sfx.play('chew');
      }, 250);
      setTimeout(e => {
        document.body.classList.add('nocursor');
        setTimeout(e => {
          document.body.classList.remove('nocursor');
          slime.classList.remove("eat");
        }, 3000);
      }, 350);
    });
}

/**
 * =======================================================================
 * 
 *  Speech bubble
 * 
 * =======================================================================
 */
let speech = d.getElementById("speech");
let speechTimer,
  nextSpeech,
  clearAndNextSpeech;

speech.style.display = 'none';

clearAndNextSpeech = e => {
  speech.style.display = 'none';
  clearTimeout(speechTimer);
  speechTimer = setTimeout(nextSpeech, 3000 + Math.random()*9000);
};

nextSpeech = e => {
  if (window.gs_data && window.gs_data.home_speech_list) {
    speech.innerHTML = _.sample(window.gs_data.home_speech_list);
  }
  speech.style.display = 'block';
  clearTimeout(speechTimer);
  speechTimer = setTimeout(clearAndNextSpeech, 4000);
};

speechTimer = setTimeout(nextSpeech, 2000);

/**
 * =======================================================================
 * 
 *  Apple
 * 
 * =======================================================================
 */
let apple = d.getElementById("apple");
apple.dataset.state = "0";

// on apple click
apple.addEventListener('click', e => {
  e.preventDefault();
  clearAndNextSpeech();

  let t = e.currentTarget;
  if (t.dataset.state === "0") {
    t.classList.add('state-1');
    t.dataset.state = "1";
  } else if (t.dataset.state === "1") {
    t.classList.remove('state-1');
    t.classList.add('state-2');
    t.dataset.state = "2";
  } else if (t.dataset.state === "2") {
    t.style.bottom = ""+String(56+Math.random()*20)+"px";
    t.style.transition = "transform 0.05s ease-in, bottom 0.05s ease-in";
    t.style.transform = "rotate("+String(-30+Math.random()*60)+"deg)";
    t.style.animationName = "unset";
    
    setTimeout(() => {
      t.style.transition = "transform 0.15s ease-in, bottom 0.15s ease-in";
      t.style.bottom = "36px";
      t.style.transform = "rotate(0)";
    }, 50);
  }

  sfx.play('bump');
});


/**
 * =======================================================================
 * 
 *  Eyeball
 * 
 * =======================================================================
 */

let animationFrame = new AnimationFrame();

let player = d.getElementById("player"),
  playerCap = d.getElementById("player-cap"),
  playerRect,
  playerTargetSpeed = 500, // px. per sec.
  playerMovingSpeed = 0,
  mouse = { left: 0, top: 0 },
  playerOriginClass = "",
  playerEvading = false,
  playerPreviousRect = {left:0, top:0},
  prevPlayerClass = "";

let timeDiff,
  prevTime = 0;

let tick = time => {
  timeDiff = 0.001 * (time - prevTime);
  prevTime = time;

  sw = w.innerWidth || e.clientWidth || g.clientWidth;
  sh = w.innerHeight || e.clientHeight || g.clientHeight;

  playerRect = player.getBoundingClientRect();

  // distance check
  if (!playerEvading && Math.abs(playerRect.left + 100 - mouse.left) < 100 && Math.abs(playerRect.top + 100 - mouse.top) < 200) {
    playerEvading = true;

    let newXPos;
    if (playerRect.left > 0.5 * sw) {
      newXPos = playerRect.left - 100 - Math.random() * 0.5 * (sw - 300);
    } else {
      newXPos = playerRect.left + 100 + Math.random() * 0.5 * (sw - 300);
    }

    let animTime = Math.round(10 * Math.abs((newXPos - playerRect.left) / playerTargetSpeed))/10;
    player.style.transitionDuration = String(animTime) + "s";
    player.style.left = String(newXPos) + "px";

    setTimeout(() => { playerEvading = false; }, animTime * 1000);
  }

  // speed
  playerMovingSpeed = playerRect.left - playerPreviousRect.left;
  playerPreviousRect = playerRect;

  // wobble
  player.style.bottom = String(36 - Math.round(Math.sin(playerRect.left/12)*4)) + "px";

  // eye movement
  let playerClass = playerOriginClass + " ";
  if (mouse.left > playerRect.left) {
    playerClass += "right";
  } else if (mouse.left <= playerRect.left) {
    playerClass += "left";
  }
  if (mouse.top > playerRect.top) {
    playerClass += "-down";
  } else if (mouse.top <= playerRect.top) {
    playerClass += "-up";
  }
  if (playerClass !== prevPlayerClass) {
    player.className = playerClass;
    prevPlayerClass = playerClass;
  }

  // cap movement
  let capRotate = -9 - playerMovingSpeed;
  let capTransX = -65 - 0.8 * playerMovingSpeed;
  let capTransY = -40 + 0.5 * Math.abs(playerMovingSpeed);
  playerCap.style.transform = `translate(${capTransX}px, ${capTransY}px) rotate(${capRotate}deg)`;

  // again!
  animationFrame.request(tick);
};

// mouse
document.addEventListener('mousemove', e => {
  mouse = { left: e.pageX, top: e.pageY };
}, false);

// setup
playerRect = player.getBoundingClientRect();
playerOriginClass = player.className;
playerPreviousRect = playerRect;

// fire
player.style.transitionDuration = '2s';
player.style.left = String(Math.round(0.5 * sw)) + 'px';

playerEvading = true;
setTimeout(() => { playerEvading = false; }, 2000);

animationFrame.request(tick);
